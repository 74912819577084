import React from "react"
import { graphql } from "gatsby"

//Components
import SEO from "../components/global/seo"
import {
  PageBlocks,
  ProjectBanner,
  RelatedProjects,
} from "../components/pageBlocks"

const ProjectPage = ({ data }) => {
  const { projectName, projectType, metaDescription, keywords, banner, main } =
    data.projectPage || {}

  const relatedProjects = data.relatedProjects.edges || null

  //filter relatedProjects by node.hidden === true
  const filteredRelatedProjects = relatedProjects
    ? relatedProjects.filter(({ node }) => !node.hidden)
    : null

  console.log('filter', filteredRelatedProjects)

  const randomRelated = filteredRelatedProjects
    ? filteredRelatedProjects.sort(() => 0.5 - Math.random()).slice(0, 3)
    : null
  return (
    <>
      <SEO
        title={`${projectName}-${projectType}`}
        keywords={keywords}
        description={metaDescription}
      />
      <ProjectBanner
        projectName={projectName}
        projectType={projectType}
        banner={banner}
      />
      {main && <PageBlocks blocks={main.blocks} />}
      {randomRelated && <RelatedProjects projects={randomRelated} />}
    </>
  )
}

// PAGE_QUERY
export const pageQuery = graphql`
  query projectPageQuery($slug: String!, $id: String!) {
    relatedProjects: allSanityProjectPage(filter: { id: { ne: $id } }) {
      edges {
        node {
          _type
          _key
          projectName
          projectType
          hidden
          slug {
            current
          }
          banner {
            asset {
              fluid(maxWidth: 600) {
                ...GatsbySanityImageFluid
              }
            }
          }
          relatedImage {
            asset {
              fluid(maxWidth: 600) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    projectPage: sanityProjectPage(slug: { current: { eq: $slug } }) {
      projectName
      projectType
      metaDescription
      keywords
      banner {
        asset {
          fluid(maxWidth: 2500) {
            ...GatsbySanityImageFluid
          }
        }
      }
      main {
        blocks {
          ... on SanityAwards {
          _key
          _type
          heading
          items {
            image {
              asset {
                fluid(maxWidth: 1440) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            text
          }
        }
          ... on SanityPageSpacer {
            _key
            _type
            size
            headerSpacer
          }
          ... on SanityFeatureBanner {
            _key
            _type
            image {
              asset {
                fluid(maxWidth: 1440) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            backgrounds {
              ... on SanityBannerImage {
                _key
                _type
                asset {
                  fluid(maxWidth: 2500) {
                    ...GatsbySanityImageFluid_noBase64
                  }
                }
              }
              ... on SanityBannerVideo {
                _key
                _type
                videoURL
              }
            }
            heading
            links {
              linkText
              pageReference {
                ... on SanityProjectPage {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityStandardPage {
                  id
                  slug {
                    current
                  }
                }
              }
            }
          }
          ... on SanityTestimonials {
            _key
            _type
            quotes {
              _key
              author
              quote
            }
          }

          ... on SanityStandardText {
            _key
            _type
            wrapped
            measured
            text {
              _rawText(resolveReferences: { maxDepth: 5 })
            }
            links {
              linkText
              pageReference {
                ... on SanityProjectPage {
                  id
                  metaDescription
                  slug {
                    current
                  }
                }
                ... on SanityStandardPage {
                  id
                  slug {
                    current
                  }
                }
              }
            }
          }
          ... on SanityImageText {
            _key
            _type
            text {
              _rawText(resolveReferences: { maxDepth: 5 })
            }
            image {
              asset {
                fluid(maxWidth: 1440) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            reverse
          }
          ... on SanityImageCarousel {
            _key
            _type
            images {
              asset {
                fluid(maxWidth: 1440) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          ... on SanityProjectCarousel {
            _key
            _type
            heading
            subheading
            images {
              asset {
                fluid(maxWidth: 1440) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            projectReference {
                projectName
                projectType
                _type
                slug {
                  current
                }
              }
          }
          ... on SanityBioCarousel {
            _key
            _type
            heading
            bios {
              image {
                asset {
                  fluid(maxWidth: 2500) {
                    ...GatsbySanityImageFluid_noBase64
                  }
                }
              }
              name
              position
            }
          }
          ... on SanityServiceScroller {
            _key
            _type
            sections {
              _key
              title
              image {
                asset {
                  fluid(maxWidth: 1440) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              text {
                _rawText(resolveReferences: { maxDepth: 5 })
              }
            }
          }
          ... on SanitySingleImage {
            _key
            _type
            align
            image {
              asset {
                fluid(maxWidth: 1440) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          ... on SanityContactDetails {
            _key
            _type
            details {
              _rawText(resolveReferences: { maxDepth: 5 })
            }
          }
          ... on SanityImageGrid {
            _key
            _type
            imageOne {
              asset {
                fluid(maxWidth: 1440) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            imageTwo {
              asset {
                fluid(maxWidth: 1440) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            imageThree {
              asset {
                fluid(maxWidth: 1440) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          ... on SanityEditorialLayout {
            _key
            _type
            text {
              _rawText(resolveReferences: { maxDepth: 5 })
            }
            imageOne {
              asset {
                fluid(maxWidth: 1440) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            imageTwo {
              asset {
                fluid(maxWidth: 1440) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            reverse
          }
          ... on SanityIntroGrid {
            _key
            _type
            text {
              _rawText(resolveReferences: { maxDepth: 5 })
            }
            projectsLink {
              _type
              slug {
                _type
                current
              }
            }
            projects {
              image {
                asset {
                  fluid(maxWidth: 1440) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              projectReference {
                projectName
                projectType
                _type
                slug {
                  current
                }
              }
            }
          }
          ... on SanityProjectGrid {
            _key
            _type
            projects {
              image {
                asset {
                  fluid(maxWidth: 1440) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              gridStart
              gridEnd
              gridAlign
              projectReference {
                projectName
                projectType
                _type
                slug {
                  current
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ProjectPage
